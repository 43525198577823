import { Injectable } from '@angular/core';
// import * as moment from 'moment-timezone';
import { BehaviorSubject } from 'rxjs';
const moment = require('moment-timezone');

@Injectable({
  providedIn: 'root'
})
export class TimeZoneService {
  private timezoneStorageKey = 'timezone';
  updatedTimezone$ : BehaviorSubject<string> = new BehaviorSubject(' ');
  constructor() {
    this.updatedTimezone$.next(this.getUserTimeZone())
   }

  setTimeZone(timeZoneName : string){
    localStorage.setItem(this.timezoneStorageKey,timeZoneName);
    // return localStorage.getItem('timezone')
    this.updatedTimezone$.next(this.getUserTimeZone());
  }

  getUserTimeZone(){
    return localStorage.getItem(this.timezoneStorageKey);
  }

  convertTimeZone(time : Date) {
    const format = 'YYYY/MM/DD HH:mm:ss';
    const zone = this.getUserTimeZone();
    if(!zone)
    {
      return time;
    }
    return moment(time, format).tz(zone).format(format);
  }

  convertTimeZoneDate(time : Date) : Date {
    const format = 'YYYY/MM/DD HH:mm:ss';
    const zone = this.getUserTimeZone();
    if(!zone)
    {
      return time;
    }
    return new Date(moment(time, format).tz(zone).format(format));
  }

  getDisplayTimeZone()
  {
    if(this.getUserTimeZone())
    {
      return 'GMT '+moment().tz(this.getUserTimeZone()).format('Z');
    }
    else{
      return 'GMT '+moment().tz(moment.tz.guess()).format('Z');
    }
  }

  timeToDate(time: string, timezone: string): Date {
    const convertedTime: moment.Moment = moment(time, 'HH:mm:ss').tz(timezone);
    return convertedTime.toDate();
  }
}
