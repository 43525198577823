import { Component, Inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, Event } from '@angular/router';
import { AuthenticationService } from './services/authentication.service';
import { TranslateService } from '@ngx-translate/core';
import { UserAuthService } from './services/user-auth.service';
// import * as io from 'socket.io-client'
import { NotificationService } from './services/notification.service';
import { OrganisationUser } from './models/auth-user';
import { LoggedInUser } from './services/managerial-operations.service';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT } from '@angular/common';
import { PluginsService } from '../lib/sofbox/plugins.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent implements OnInit {
  socket: any;
  organisationCode: string;
  constructor(
    private router: Router,
    private authService: AuthenticationService,
    public translate: TranslateService,
    public uauthService: UserAuthService,
    public notify: NotificationService,
    public dialog: MatDialog,
    @Inject(DOCUMENT) private document: Document,
    public plugin: PluginsService,
  ) {
    // this.socket= io(environment.apiEndpoint)
  }

  title = 'foucsro-web-app';

  ngOnInit() {
    let classList = this.document.body.classList;
    const lang = localStorage.getItem('lang');
    if(classList.value !=''){
     this.document.body.classList.replace(classList[0],lang);
    }else if(classList.value =='' && lang==null){
     this.document.body.classList.add('english');
    }else{
      this.document.body.classList.add(lang);
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    
    // this.authService.getCurrentUserInfo();
    // this.authService.listenAuthEvents();
    this.authService.getCurretUser().subscribe(
      (userInfo) => {
        if(userInfo.roleId ==2 || userInfo.roleId ==1)
        {
          if(this.uauthService.checkTokenExpired(this.uauthService.getAuthToken()))
          {
            this.uauthService.refreshToken().subscribe();
          }
        }
        if (userInfo instanceof LoggedInUser) {
          this.organisationCode = userInfo.organisationCode;
        } else if (userInfo instanceof OrganisationUser) {
          this.organisationCode = userInfo.organisationCode;
        }
      },
      (err) => console.log(err)
    );

    // this.socket.on('notification', (data)=> {
    //   if(this.organisationCode==data.orgCode && data.type== "report-download"){
    //      this.notify.showInfo(`${data.message}`,"Notification !!").onTap
    //      .pipe(take(1))
    //      .subscribe(() => {
    //       this.router.navigateByUrl('/app/analysis/export-csv-report');
    //      });
    //   }
    // });

    this.router.events.subscribe(event => {

      if (event instanceof NavigationEnd) {

        // close all open modals
        this.dialog.closeAll();        

      }
    });

    //following code used for activate menu link
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
          const currentRoute = event.url.split('?')[0];
          if(currentRoute == '/app/gallery/report'){
            this.plugin.activateLink('/app/gallery');
          }
          // if(
          // currentRoute == '/app/task-reports/organisation-report'
          // || currentRoute == '/app/task-reports/client-report'
          // || currentRoute == '/app/task-reports/project-report'
          // || currentRoute == '/app/task-reports/employee-report'
          // ){
          //   this.plugin.activateTaskMenuLinks(currentRoute);
          // }
      }
    },err=> console.log(err));
    
  }
}
