export class OrganisationUser {
    id: number;
    organisationCode: string | null;
    name: string;
    email: string;
    nickName: string;
    roleId: number;
    setupCompleted: boolean;
    customTaskName: string;
    customClientName: string;
    customProjectName: string;
    subscriptionPlanId: number;
    upgradePlanWarning: number;
    trialEndDate: Date;
    isInvoicePending: number;
    orgTimezone: string;
}

export class CurrentUser {
    id:number
    CognitoUser: any;
    UserDetails: OrganisationUser;
    roleId?:number;
}

export interface IOrganisationProfile {
    id: number;
    name: string;
    email: string;
    nickName: string;
    code: string;
    customTaskName: string;
}

export interface IManagerUserProfile {
    email: string;
    fullName: string;
    id: number;
    designationName: string;
    organisationCode: string;
    customTaskName: string;
}
