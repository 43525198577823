<div class="text-center dialog_width">
    <h4>{{data.message}}</h4>
    <br>

    <h4 class="note-point" *ngIf="data.notePoint">Note: {{data.notePoint}}</h4> <!-- Add this line to display the notePoint -->

    <p class="text-danger" *ngIf="isIrreversible">
        <span class="font-weight-bold">{{"COMMON.WARNING" | translate}}!</span>
        {{"COMMON.ACTION_IRREVERSIBLE" | translate}}
    </p>
    <div class="mt-5 text-center d-flex justify-content-between">
        <button class="btn btn-outline-danger mr-3" (click)="onNoClick()">{{"COMMON.CANCEL" | translate}}</button>
        <button class="btn btn-outline-primary" (click)="onYesClick()">{{"COMMON.YES" | translate}}</button>
    </div>
</div>