import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PluginsService {

  // @ts-ignore
  public $ = window.$;

  constructor() { }

  public index(): void {
    this.loaderInit();
  }

  public dashboardIndex(): void {
    this.sideBarToggle(this.$);
    this.navBarAction(this.$);
    this.wrapperMenuToggle(this.$);
    this.fullscreen(this.$);
    this.accordionToggle(this.$);
    //close menu in mobile after link clicked
    this.closeMenuInMobile(this.$);
  }

  // Function for toggle page Loader...
  public loaderInit(): void {
    this.$('#load').fadeOut();
    this.$('#loading').delay(1000).fadeOut('slow');
  }

  public checkElement(type, element): boolean {
    let found = false;
    let elements;
    switch (type) {
      case 'class':
        elements = document.getElementsByClassName(element);

        if (elements !== undefined && elements !== null && elements.length > 0) {
          found = true;
        }
        break;

      case 'id':
        elements = document.getElementById(element);

        if (elements !== undefined && elements !== null) {
          found = true;
        }
        break;
    }

    return found;
  }

  public sideBarToggle($): void {
    const elementExist = this.checkElement('class', 'iq-sidebar-menu');

    if (elementExist) {      
      $('.iq-sidebar-menu a').on('click', function () {
        const checkElement = $(this).next().children();
        // console.log(checkElement);
        $('.iq-sidebar-menu li').removeClass('active');
        $(this).closest('li').addClass('active');
        if ((checkElement.is('ul')) && (checkElement.is(':visible'))) {
          $(this).closest('li').removeClass('active');
          checkElement.slideUp('normal');         
        }
        if ((checkElement.is('ul')) && (!checkElement.is(':visible'))) {
          if(!$(this).hasClass('grand-child')){
            $('.iq-sidebar-menu ul ul:visible').slideUp('normal');
                     }
          checkElement.slideDown('normal');        
          
        }
        if(!$(this).parent().parent().hasClass('iq-submenu') && $(this).hasClass('submenu')){
          $('.iq-sidebar-menu ul ul:visible').slideUp('normal');         
        }
        if($('.grand-child').parent('li').hasClass('iq-submenu') && $(this).hasClass('submenu')){         
          $('.grand-child').parent('li').addClass('active');        
          $('.grand-child').parents('li').find('ul.iq-submenu').css({'display':'block'});      
        }
        if ($(this).closest('li').find('ul').children().length === 0) {
          return true; 
        } else {
          return false;
        }
        
      });
      // $('.grand-child').parents('li').find('ul.iq-submenu').css({'display':'none'});
      // $('body').bind('click','.grand-child app-list-style1 .iq-submenu li .submenu', function(){
      //   $('.grand-child').parent('li').addClass('active');        
      //   $('.grand-child').parents('li').find('ul.iq-submenu').css({'display':'block'});
      // });
    }
  }

  public navBarAction($) {
    $(document).unbind('click').on('click', function (e) {
      const myTargetElement = e.target;
      // tslint:disable-next-line:one-variable-per-declaration
      let selector, mainElement;
      if ($(myTargetElement).hasClass('search-toggle')
        || $(myTargetElement).parent().hasClass('search-toggle')
        || $(myTargetElement).parent().parent().hasClass('search-toggle')) {
        if ($(myTargetElement).hasClass('search-toggle')) {
          selector = $(myTargetElement).parent();
          mainElement = $(myTargetElement);
        } else if ($(myTargetElement).parent().hasClass('search-toggle')) {
          selector = $(myTargetElement).parent().parent();
          mainElement = $(myTargetElement).parent();
        } else if ($(myTargetElement).parent().parent().hasClass('search-toggle')) {
          selector = $(myTargetElement).parent().parent().parent();
          mainElement = $(myTargetElement).parent().parent();
        }
        if (!mainElement.hasClass('active') && $('.navbar-list li').find('.active').length > 0) {
          $('.navbar-list li').removeClass('iq-show');
          $('.navbar-list li .search-toggle').removeClass('active');
        }

        selector.toggleClass('iq-show');
        mainElement.toggleClass('active');

        e.preventDefault();
      } else if ($(myTargetElement).is('.search-input')) { } else {
        $('.navbar-list li').removeClass('iq-show');
        $('.navbar-list li .search-toggle').removeClass('active');
      }
    });
  }

  public wrapperMenuToggle($) {
    const elementExist = this.checkElement('class', 'wramenucus');
    if (elementExist) {
      const wrapperMenu = document.querySelectorAll('.wramenucus');
      const body = document.querySelector('body');
      for (let i = 0; i < wrapperMenu.length; i++) {
        wrapperMenu[i].addEventListener('click', function () {
          wrapperMenu[i].classList.toggle('open');
          body.classList.toggle('sidebar-main');
        });
      }
    }
  }

  public fullscreen($) {
    const elementExist = this.checkElement('class', 'iq-full-screen');
    if (elementExist) {
      $(document).on('click', '.iq-full-screen', function () {
        /*const elem = $(this);
        if (!document.fullscreenElement &&
          !document.mozFullScreenElement &&
          !document.webkitFullscreenElement &&
          !document.msFullscreenElement) {
          if (document.documentElement.requestFullscreen) {
            document.documentElement.requestFullscreen();
          } else if (document.documentElement.mozRequestFullScreen) {
            document.documentElement.mozRequestFullScreen();
          } else if (document.documentElement.webkitRequestFullscreen) {
            document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
          } else if (document.documentElement.msRequestFullscreen) {
            document.documentElement.msRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
          }
        } else {
          if (document.cancelFullScreen) {
            document.cancelFullScreen();
          } else if (document.mozCancelFullScreen) {
            document.mozCancelFullScreen();
          } else if (document.webkitCancelFullScreen) {
            document.webkitCancelFullScreen();
          } else if (document.msExitFullscreen) {
            document.msExitFullscreen();
          }
        }
        elem.find('i').toggleClass('ri-fullscreen-line').toggleClass('ri-fullscreen-exit-line');*/
      });
    }
  }

public accordionToggle($) {
  $('.iq-accordion .iq-accordion-block .accordion-details').hide();
  $('.iq-accordion .iq-accordion-block:first').addClass('accordion-active').children().slideDown('slow');
  $(document).on('click', '.iq-accordion .iq-accordion-block', function () {
    if ($(this).children('div.accordion-details ').is(':hidden')) {
      $('.iq-accordion .iq-accordion-block').removeClass('accordion-active').children('div.accordion-details ').slideUp('slow');
      $(this).toggleClass('accordion-active').children('div.accordion-details ').slideDown('slow');
    }
  });
}

public activateLink(path: string){
  setTimeout(()=>{
    const link = this.$(`a[href="${path}"]`).get()[0];
    link.parentElement.parentElement.parentElement.parentElement.className= 'active'
    this.$(link.parentElement.parentElement).slideDown()
    link.parentElement.className = 'active';
  },10)
}
// public activateTaskMenuLinks(path: string){
//   setTimeout(()=>{
//     const link = this.$(`a[href="${path}"]`).get()[0];
//     link.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.parentElement.className= 'active'
//     link.parentElement.parentElement.parentElement.parentElement.parentElement.className = 'active'
//     link.parentElement.parentElement.parentElement.parentElement.className= 'active'
//     this.$(link.parentElement.parentElement).slideDown()
//     link.parentElement.className = 'active';
//   },10)
// }
  public closeMenuInMobile($){
    if(this.detectMobile()){
      $('.submenu').on('click', ()=>{
        $('body').removeClass('sidebar-main');
        $('.iq-menu-bt').click();
      })
    }
  }
  detectMobile() {
    if (navigator.userAgent.match(/Android/i)
         || navigator.userAgent.match(/webOS/i)
         || navigator.userAgent.match(/iPhone/i)
         || navigator.userAgent.match(/iPad/i)
         || navigator.userAgent.match(/iPod/i)
         || navigator.userAgent.match(/BlackBerry/i)
         || navigator.userAgent.match(/Windows Phone/i)) {
            return true;
         }
      return false;
  }
}
