import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { IAPIResponse } from './dashboard.service';
import { UtilityService } from './utility.service';

@Injectable({
  providedIn: 'root'
})
export class SuperAdminService {

  private url = environment.apiEndpoint;

  constructor(
    private http: HttpClient,
    private util: UtilityService
  ) { }

  getOrgInvoiceData(orgCode: string, invoiceDate: Date) {
    let reqUrl = `${this.url}/api/v1/organisation-management/organisations/${orgCode}/invoice/`;
    reqUrl += this.util.extractDate(invoiceDate);
    return this.http.get<IAPIResponse<InvoiceData>>(reqUrl);
  }

}

export enum Roles {
  SuperAdmin = 1,
  OrgUser,
  OrgManagerUser,
  EmployeeUser = 4
}

export interface IOrganisation {
  id: number;
  organisationCode: string;
  name: string;
  email: string;
  nickName: string;
  domain: string;
  roleId: number;
  setupCompleted: boolean;
  pilotProgram: boolean;
  dbHostUrl: string;
  dbHostPort: number;
  timezone: string;
  createdAt: Date;
  status: number;
  isupdatedocker: boolean;
  lastLogin: Date;
  lastImageProcessTime?: string;
}

export interface IOrganisationDetails extends IOrganisation {
  dbName: string;
  dbUsername: string;
  dbPassword?: string;
  serverPath: string;
  publicPemKey: string;
  imageUploadUrl: string;
  mltrainingUrl: string;
  imageHost: string;
  stripeCustId: string;
  dockerid: string;
  dockerUpdateUrl: string;
  clusterUrl: string;
  garbageUrl: string;
  garbageCollectionTime: string;
  garbageDays: number;
  garbageLastUpdate: Date;
  enableGarbageCollection: boolean;
  emailDigestDataGenTime: string;
  trialPeriodDays: number;
  subscriptionPlanId: number;
  intimatePlanUpdate: boolean;
}

export interface IOrganisationListResponse {
  statusCode: number;
  message: string;
  data: { count: number, organisations: IOrganisation[] };
}

export interface IOrganisationEmployeeDetais {
  registeredEmployeeCount: number;
  inviteAcceptedCount: number;
  inviteNotAcceptedCount: number;
  currentMonthActiveEmployees: number;
  processedImageCount: number;
  currentMonthProcessdImageCount: number;
  lastImageTime: Date;
}

export interface IOrganisationDetailsData {
  details: IOrganisationDetails;
  employees: IOrganisationEmployeeDetais;
}

export interface IOrganisationDetailsResponse {
  statusCode: number;
  data: { organisation: IOrganisationDetailsData };
}

export interface IUpdateDockerHistory {
  id: number;
  organisationId: number;
  previousDockerId: string;
  newDockerId: string;
  status: number;
  response: { value: string; status: number };
  createdAt: Date;
  organisation: {
    organisationCode: string;
    name: string
  };
}

export interface IUpdateDockerHistoryData {
  count: number;
  history: IUpdateDockerHistory[];
}


// Update response status enum
export enum UpdateResponseStatus {
  FAILED = 0,
  SUCCESS = 1,
  PENDING = 2,
}

export interface IGarbageCollectionHistory {
  id: number;
  organisationId: number;
  dockerId: string;
  timestamp: Date;
  status: number;
  response: { value: string; status: number } | null;
  createdAt: Date;
  updatedAt: Date;
  organisation: {
    id: number;
    organisationCode: string;
    name: string
  };
}

export interface IGarbageCollectionHistoryData {
  count: number;
  history: IGarbageCollectionHistory[];
}

export interface IGarbageCollectionDetails {
  id: number;
  totalProcessedImage: number;
  createdAt: Date;
  updatedAt: Date;
}

export interface IGarbageCollectionDetailsData {
  count: number;
  details: IGarbageCollectionDetails[];
}

export interface InvoiceData {
  organisationName: string;
  organisationCode: string;
  activeEmployeeCount: number;
  processedImageCount: number;
  costPerEmployee: number;
  taxRate: number;
}

export interface Invoice{
  id: number;
  organisationId: number;
  stripeInvoiceId: string;
  activeEmployeeCount: number;
  pricePerUser: number;
  totalAmount: number;
  createdAt: Date;
  updatedAt: Date;
}