<div class="container">
    <div class="row">
        <div class="col-sm-12">
            <div class="tableplatformlist mt-4 dash_img">
                <div class="text-center">
                    <h4>Account disabled</h4>
                    <img src="assets/images/account_locked.png" width="200">
                    <p class="text-dark">
                        Your account has been disabled. If you have any queries or concerns please contact
                        <a href="mailto:support@focusro.com" class="text-lowercase">support@focusro.com</a>
                    </p>
                    <p>
                        <a class="btn btn-outline-primary" routerLink="/" role="button">
                            <i class="ri-home-7-line"></i> Go home
                        </a>
                    </p>
                </div>
            </div>
        </div>
    </div>
</div>