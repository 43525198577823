import { Injectable } from '@angular/core';
import { Router, CanActivateChild } from '@angular/router';
import { Observable, of } from 'rxjs';
import { ManagerialOperationsService } from './managerial-operations.service';
import { UserAuthService } from './user-auth.service';
import { CognitoRedirectService } from './cognito-redirect.service';


/**
 * @classdesc
 * Activates the route only if the user is signed in.
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivateChild {

  constructor(
    private managerOperations: ManagerialOperationsService,
    private router: Router,
    private uauthSer: UserAuthService,
    private cognitoUserRedirect:CognitoRedirectService
  ) { }

  /**
   * Decides wehter the guard can activate the route based on the user's signed in status
   * @returns A boolean Observable with ture if user has already signed in
   */
  canActivateChild(): Observable<boolean> {

    //redirect already logged in cognito user
    this.cognitoUserRedirect.cognitoUserRedirect();

    if (this.managerOperations.isLoggedIn()) {
      return of(this.managerOperations.isLoggedIn());
    }
    if (this.uauthSer.isLoggedIn()) {
      return of(this.uauthSer.isLoggedIn());
    }
    this.router.navigateByUrl('/auth/login');
    return of(false);
  }
}
