export const environment = {
  production: true,
  apiEndpoint: 'https://dev-api.focusro.com',
  timeout: 5000,
  imageEndpoint: 'https://dev-screen.focusro.com/',
marketingApi: 'https://dev-marketing-api.focusro.com',
  pabblyCheckoutUrl: 'https://payments.pabbly.com/subscribe/63b6cc58215f2a0aae6e8bae/free-trial',
  pabblyCheckoutUrlIN: 'https://payments.pabbly.com/subscribe/642fb43889a8cb1840f45fb7/free-in',
  appTrackingDeploymentDate: '2023-01-20',
  orgCode:["RSB"]
};
