<!-- Wrapper Start -->
<div class="wrapper">
    <div class="container-fluid p-0">
        <div class="row no-gutters">
            <div class="col-sm-12 text-center">
                <div class="iq-error">
                    <h1>403</h1>
                    <h4 class="mb-0">Access denied!</h4>
                    <p>You do not have the previlages to access the page</p>
                    <a class="btn btn-outline-primary mt-3" routerLink="/">
                        <i class="ri-home-4-line"></i>Back to Home
                    </a>
                    <img src="./assets/images/error/01.png" class="img-fluid error-img" alt="">
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Wrapper END -->