import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ManagerialOperationsService } from '../services/managerial-operations.service';
import {  UserAuthService } from '../services/user-auth.service';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        private router: Router,
        private managerService: ManagerialOperationsService,
        private uauthSer: UserAuthService,
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(catchError(async (err: HttpErrorResponse) => {
            // console.error('ErrorInterceptor', err);
            switch (err.status) {
                case 400:
                    if (
                        err.error
                        && err.error.data
                        && err.error.data.error
                        && err.error.data.error.code
                        && err.error.data.error.code === 'USER_DISABLED'
                        && this.managerService.isLoggedIn()
                    ) {
                        const manager = this.managerService.getCurrenUser();
                        this.managerService.logOut(manager.id);
                    }
                    break;
                case 401:
                    if (
                        err.error
                        && err.error.data
                        && err.error.data.error
                        && err.error.data.error.code
                    ) {

                        if(err.error.data.error.code === 'REFRESH_TOKEN_INVALID'){
                            if(this.uauthSer.isLoggedIn()){
                                const user= this.uauthSer.getCurrenUser();
                                this.uauthSer.logOut(user.id);
                            }
                            if(this.managerService.isLoggedIn()){
                                const manager = this.managerService.getCurrenUser();
                                this.managerService.logOut(manager.id);
                            }
                        }

                        if(err.error.data.error.code === 'TOKEN_EXPIRED_ERROR'){

                            if(this.managerService.isLoggedIn())
                            {
                                const token= this.managerService.getAuthToken() 
                                if(token)
                                {
                                    const manager = this.managerService.getCurrenUser();
                                    this.managerService.refreshTokenForManager(manager.organisationCode).subscribe()
                                }
                            }
                            else if(this.uauthSer.isLoggedIn())
                            {
                                const token= this.uauthSer.getAuthToken() 
                                if(token)
                                {
                                    this.uauthSer.refreshToken().subscribe()
                                }
                            }
                        }
                    }
                    break;
                case 403:
                    if (
                        err.error
                        && err.error.data
                        && err.error.data.error
                        && err.error.data.error.code
                        && err.error.data.error.code === 'ORGANISATION_DISABLED_ERROR'
                    ) {
                        this.router.navigateByUrl('/account-disabled');
                    } else if (
                        err.error
                        && err.error.data
                        && err.error.data.error
                        && err.error.data.error.code
                        && err.error.data.error.code === 'TOKEN_VERIFICATION_FAILED_ERROR'
                    ) {
                        const manager = this.managerService.getCurrenUser();
                        this.managerService.logOut(manager.id);
                    } else {
                        this.router.navigateByUrl('/403');
                    }
                    break;
                case 500:
                    this.router.navigateByUrl('/500');
                    break;

                case 402:
                    this.router.navigateByUrl('/app/402');
                    break;
                case 410:
                    this.router.navigateByUrl('/app/410');
                    break;
                case 503:
                    this.router.navigateByUrl('/app/503');
                    break;
            }
            throw err;
        }));
    }
}
