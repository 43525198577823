import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TimeZoneRoutingModule } from './time-zone-routing.module';
import { CustomTimezoneComponent } from './custom-timezone/custom-timezone.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AlertModule } from 'ngx-bootstrap/alert';
import { PipesModule } from '../../pipes/pipes.module';
import { ConfirmationDialogComponent } from '../../shared/confirmation-dialog/confirmation-dialog.component';
import { SharedModule } from '../../shared/shared.module';


@NgModule({
  declarations: [CustomTimezoneComponent],
  imports: [
    CommonModule,
    TimeZoneRoutingModule,
    FormsModule,
    TranslateModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    AlertModule,
    PipesModule,
    MatDialogModule,
    SharedModule,
    ReactiveFormsModule
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ],
  exports:[
    CustomTimezoneComponent
  ]
})
export class TimeZoneModule { }
