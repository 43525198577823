import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class RefreshService {
  private refreshSubject = new BehaviorSubject<string | null>(null);

  triggerRefresh(componentIdentifier: string) {
    this.refreshSubject.next(componentIdentifier);
  }

  getRefreshObservable() {
    return this.refreshSubject.asObservable();
  }

}