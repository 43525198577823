import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface IConfirmActionDialogData {
  message: string;
  notePoint?:string;
  isIrreversible?: boolean;
}
@Component({
  selector: 'fcsro-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.css']
})
export class ConfirmationDialogComponent {
  isIrreversible: boolean;
  constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: IConfirmActionDialogData
  ) { 
    if(data.isIrreversible == false)
      this.isIrreversible = data.isIrreversible;
    else
      this.isIrreversible = true;
  }

  onNoClick(): void {
    this.dialogRef.close(false);
  }

  onYesClick(): void {
    this.dialogRef.close(true);
  }

}
