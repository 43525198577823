import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LanguageConfigService } from 'src/app/services/language.service';
@Component({
  selector: 'fcsro-place-holder',
  templateUrl: './place-holder.component.html',
  styleUrls: ['./place-holder.component.css']
})
export class PlaceHolderComponent implements OnInit {

  constructor(
    private langService: LanguageConfigService,
    public translate: TranslateService
  ) {
    translate.setDefaultLang(langService.getDefaultLanguage());
    translate.use(langService.getDefaultLanguage());
  }

  ngOnInit() {
    this.translate.use(this.langService.getCurrentLanguage());
  }

}
