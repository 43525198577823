import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DataLoaderComponent } from './data-loader/data-loader.component';
import { HorizontalCalendarComponent } from './horizontal-calendar/horizontal-calendar.component';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSliderModule } from '@angular/material/slider';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimeBarComponent } from './time-bar/time-bar.component';
import { ClassificationDistributionComponent } from './classification-distribution/classification-distribution.component';
import { PlaceHolderComponent } from './place-holder/place-holder.component';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { CalanderMonthPickerHeaderComponent } from './calander-month-picker-header/calander-month-picker-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { DataTableComponent } from './data-table/data-table.component';
import { AlertModule } from 'ngx-bootstrap/alert';
import { SearchDataTableComponent } from './search-data-table/search-data-table.component';
import { WeekDayPickerComponent } from './week-day-picker/week-day-picker.component';
import { TimeBarV2Component } from './time-bar-v2/time-bar-v2.component';
import { NewDataLoaderComponent } from './new-data-loader/new-data-loader.component';
import { PieChartComponent } from './pie-chart/pie-chart.component';
import { CommingSoonComponent } from './comming-soon/comming-soon.component';
import { BarChartComponent } from './bar-chart/bar-chart.component';
import { TimeBarV3Component } from './time-bar-v3/time-bar-v3.component';
import { CurrentStatusComponent } from './current-status/current-status.component';
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient);
}


@NgModule({
  declarations: [
    DataLoaderComponent,
    HorizontalCalendarComponent,
    TimeBarComponent,
    ClassificationDistributionComponent,
    PlaceHolderComponent,
    ConfirmationDialogComponent,
    CalanderMonthPickerHeaderComponent,
    DataTableComponent,
    SearchDataTableComponent,
    WeekDayPickerComponent,
    TimeBarV2Component,
    NewDataLoaderComponent,
    PieChartComponent,
    CommingSoonComponent,
    BarChartComponent,
    TimeBarV3Component,
    CurrentStatusComponent
  ],
  imports: [
    CommonModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatSliderModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    FormsModule,
    TranslateModule,
    AlertModule,
    ReactiveFormsModule,
    MatButtonToggleModule,
    MatTooltipModule,
    MatIconModule,
  ],
  exports: [
    DataLoaderComponent,
    HorizontalCalendarComponent,
    TimeBarComponent,
    TimeBarV2Component,
    ClassificationDistributionComponent,
    PlaceHolderComponent,
    ConfirmationDialogComponent,
    DataTableComponent,
    SearchDataTableComponent,
    WeekDayPickerComponent,
    NewDataLoaderComponent,
    PieChartComponent,
    CommingSoonComponent,
    BarChartComponent,
    TimeBarV3Component,
    CurrentStatusComponent,
    MatTooltipModule,
    MatIconModule
  ],
  entryComponents: [
    CalanderMonthPickerHeaderComponent
  ]
})
export class SharedModule { }
