import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormControl } from "@angular/forms";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatDialog } from "@angular/material/dialog";
// import * as momentTZ from "moment-timezone";
const momentTZ = require("moment-timezone");
import { Observable } from "rxjs";
import { startWith } from "rxjs/operators";
import { concatMap, map } from "rxjs/operators";
import { ITimezoneHistory } from "src/app/models/organisation-user";
import { AuthenticationService } from "src/app/services/authentication.service";
import { OrganisationUserService } from "src/app/services/organisation-user.service";
import { TimeZoneService } from "src/app/services/time-zone.service";
import { environment } from "src/environments/environment";
import { ConfirmationDialogComponent, IConfirmActionDialogData } from "../../../shared/confirmation-dialog/confirmation-dialog.component";
import { COUNTRIES, getCountryCodeByTimezone } from "../../../utils/helper";
import { RefreshService } from "src/app/services/refresh.service";
export interface ICountryNameList {
  name: string
  code: string
}
@Component({
  selector: "fcsro-custom-timezone",
  templateUrl: "./custom-timezone.component.html",
  styleUrls: ["./custom-timezone.component.css"],
})
export class CustomTimezoneComponent implements OnInit {
  timeZoneNames: string[];
  timeZoneSelect: string = "";
  timezoneOffsets: string[] = [];
  filteredOptions: string[] = [];
  // timezoneList: string[] = [];//ITimezoneList[];
  orgTzFilteredOptions: string[] = [];//ITimezoneList[];
  orgTimezone: string = "";
  isOrgTzSuccess: boolean;
  isOrgTzFailed: boolean;
  isLTZsuccess: boolean;
  timeout: number = environment.timeout;
  userCountry: string = "";
  @Input() isFirstTimeLoad: boolean;
  @Output() timezoneChanged = new EventEmitter<boolean>();
  @ViewChild(MatAutocompleteTrigger, {
    static: true,
    read: MatAutocompleteTrigger,
  })
  inputAutoComplete: MatAutocompleteTrigger;
  timezoneHistory: ITimezoneHistory[] = [];
  timezoneHistoryLoading: boolean;
  country: FormControl = new FormControl('');
  countries: ICountryNameList[] = COUNTRIES;
  countryFilteredOptions: Observable<ICountryNameList[]>;
  timezoneDisable: boolean = false

  constructor(
    public timezoneServ: TimeZoneService,
    private authService: AuthenticationService,
    private orgService: OrganisationUserService,
    private confirmDialog: MatDialog,
    public refreshService: RefreshService,
    private cdr: ChangeDetectorRef,


  ) {
    this.timeZoneNames = momentTZ.tz.names();
    for (let name of this.timeZoneNames) {
      this.timezoneOffsets.push(name + " (GMT" + momentTZ().tz(name).format("Z") + ')');
    }
    this.filteredOptions = this.timezoneOffsets;
    this.orgTzFilteredOptions = this.timezoneOffsets;
  }

  ngOnInit() {
    const currentTimezone = this.timezoneServ.getUserTimeZone();
    if (currentTimezone) {
      this.timeZoneSelect = currentTimezone
        ? currentTimezone + " " + momentTZ().tz(currentTimezone).format("Z")
        : "";
    }
    this.authService.getCurretUserOrgCode()
      .pipe(
        concatMap((orgCode) => {
          return this.orgService.getAppSettings(orgCode);
        }),
        map((response) => {
          return response.data.appSettings.timezone;
        })
      )
      .subscribe((timezone) => {
        if (timezone) {
          //this.orgTimezone = timezone ? timezone : "";
        }
      }, err => {
        console.log(err);
      })
    this.loadTimezoneHistory();
    this.countryFilteredOptions = this.country.valueChanges.pipe(
      startWith(''),
      map((value: ICountryNameList | string) => {
        if (typeof value === 'object') {
          return this._filter(value.name);
        }
        return this._filter(value);
      })
    );
    //this.setCountryBasedTimezone(currentTimezone);
    this.getUserCountry();
    // this.refreshService.refreshSubject.subscribe((res) => {
    //   if (res) {
    //     // this.timezoneHistoryLoading = true;
    //     this.refreshCall()

    //   }
    //   // Reload the content or perform any action needed on refresh
    // });
    this.refreshService.getRefreshObservable().subscribe((componentIdentifier) => {
      if (componentIdentifier === '/app/timezone/timezone-customization') {
        this.timezoneHistoryLoading = true;
        this.refreshCall();
      }
      // Reload the content or perform any action needed on refresh
    });
  }

  timezoneChange() {
    const timezone = this.timeZoneSelect
      ? this.timeZoneSelect.split(" ")[0]
      : "";
    if (timezone) {
      this.isLTZsuccess = true;
    }
    this.timezoneServ.setTimeZone(timezone);
  }
  search() {
    this.filteredOptions = this.timezoneOffsets.filter((value) =>
      value.toLowerCase().includes(this.timeZoneSelect.toLowerCase())
    );
  }
  close(event) {
    event.stopPropagation();
    this.timeZoneSelect = "";
    this.filteredOptions = this.timezoneOffsets;
    this.timezoneChange();
    this.inputAutoComplete.openPanel();
  }

  orgTzSearch() {
    if (this.country.value.code) {
      this.orgTzFilteredOptions = momentTZ.tz.zonesForCountry(this.country.value.code).map(name => name + " (GMT" + momentTZ().tz(name).format("Z") + ')').filter((value) =>
        value.toLowerCase().includes(this.orgTimezone.toLowerCase()));
    } else {
      this.orgTzFilteredOptions = this.timezoneOffsets.filter((value) =>
        value.toLowerCase().includes(this.orgTimezone.toLowerCase()))
    }
  }
  orgTzClose(e) {
    e.stopPropagation();
    this.orgTimezone = "";
    this.orgTzFilteredOptions = this.timezoneOffsets;
    if (this.country.value.code) {
      this.orgTzFilteredOptions = momentTZ.tz.zonesForCountry(this.country.value.code).map(name => name + " (GMT" + momentTZ().tz(name).format("Z") + ')');
    }
  }
  orgTzChange() {
    if (this.isFirstTimeLoad) {
      this.tzChanged();
    } else {
      const dialogRef = this.confirmDialog.open<ConfirmationDialogComponent, IConfirmActionDialogData, boolean>(ConfirmationDialogComponent, {
        data: {
          message: "Changing the organization time zone setting has no effect on the values in the previous data. it will be effected only on upcoming reports",//this.translate.instant('EMPLOYEE.CONFIRM_REMOVE_EMPLOYEE'),
          isIrreversible: false
        }
      });
      dialogRef.afterClosed().subscribe((dialogResult) => {
        if (dialogResult) {
          this.tzChanged();
        }
      });
    }
  }

  loadTimezoneHistory() {
    this.timezoneHistoryLoading = true;
    this.authService.getCurretUserOrgCode()
      .subscribe(orgCode => {
        this.orgService.getTimezoneHistory(orgCode).subscribe(res => {
          this.timezoneHistory = res.data.timezoneHistory
          this.timezoneHistoryLoading = false;
          // const lastUpdatedCountry = res.data.timezoneHistory[0].country;
          const lastUpdatedCountry = res.data.timezoneHistory[0] ? res.data.timezoneHistory[0].country : this.userCountry;
          const getCountryObj = this.countries.find(country => country.name === lastUpdatedCountry);

          if (getCountryObj) {
            this.country.setValue(getCountryObj);
          }
          // if (res.data.timezoneHistory[0]) {
          //   this.orgTimezone = res.data.timezoneHistory[0] ? res.data.timezoneHistory[0].newTimezone : ''

          // }
          // if (res.data.timezoneHistory[0]&&!res.data.timezoneHistory[0].country) {
          //   this.orgTimezone = ''
          //   this.country.setValue('');
          // }

          if (res.data.timezoneHistory && res.data.timezoneHistory.length > 0) {
            // Check if timezoneHistory array exists and has at least one element
            const firstHistoryEntry = res.data.timezoneHistory[0];
            
            if (firstHistoryEntry) {
                // Check if the first entry in timezoneHistory exists
                this.orgTimezone = firstHistoryEntry.newTimezone || '';
        
                if (!firstHistoryEntry.country) {
                    // Check if country is not defined in the first entry
                    this.orgTimezone = '';
                    this.country.setValue('');
                }
            }
        }
        },
          err => {
            console.log(err);
            this.timezoneHistoryLoading = false;
          })
      });
  }

  tzChanged() {
    this.authService.getCurretUserOrgCode()
      .pipe(
        concatMap((orgCode) => {
          return this.orgService.updateTimezone(orgCode, {
            timezone: this.orgTimezone ? this.orgTimezone.split(' ')[0] : this.orgTimezone,
            country: this.country.value.name
          });
        })
      )
      .subscribe(() => {
        this.isOrgTzSuccess = true;
        this.isOrgTzFailed = false;
        if (!this.isFirstTimeLoad) {
          this.loadTimezoneHistory();
        }
        this.timezoneChanged.emit();
      }, (err) => {
        console.error(err);
        this.isOrgTzSuccess = false;
        this.isOrgTzFailed = true;
      });
    const timezone = this.orgTimezone ? this.orgTimezone.split(' ')[0] : "";
    this.timezoneServ.setTimeZone(timezone);
    if (!this.country.value.code) {
      this.setCountryBasedTimezone(timezone);
    }
  }

  private _filter(value: string) {
    const filterValue = value.toLowerCase();
    const filterdValues = this.countries.filter(option => {
      return option.name.toLowerCase().includes(filterValue);
    });
    return filterdValues;
  }
  countryAutoCompleteDisplay(country: ICountryNameList): string {
    return country ? country.name : '';
  }
  onCountrySelectChange(country: ICountryNameList) {
    this.orgTzFilteredOptions = momentTZ.tz.zonesForCountry(country.code).map(name => name + " (GMT" + momentTZ().tz(name).format("Z") + ')');
    this.orgTimezone = null;
    this.timezoneDisable = false
  }

  countrySelectClose(e) {
    e.stopPropagation();
    this.country.setValue('');
    this.countryFilteredOptions = this.country.valueChanges.pipe(
      startWith(''),
      map((value: ICountryNameList | string) => {
        if (typeof value === 'object') {
          return this._filter(value.name);
        }
        return this._filter(value);
      })
    );
    this.timezoneDisable = true
    this.orgTimezone = ''
  }

  setCountryBasedTimezone(timezone: string) {
    const country = getCountryCodeByTimezone(timezone);
    const countryCode = country ? country : null;
    if (countryCode) {
      const value = this.countries.find(obj => obj.code == countryCode);
      this.country.setValue(value);
    }
  }


  getUserCountry() {
    this.authService.getCurretUserOrgCode()
      .subscribe(orgCode => {
        this.orgService.getUserCountryCode(orgCode).subscribe(res => {
          this.userCountry = res.data.country;
          const value = this.countries.find(obj => obj.code == this.userCountry);
          // if (!this.country.value && this.timezoneHistory[0] && this.timezoneHistory[0].country) {
          //   this.country.setValue(value);
          // }
          // if(!this.timezoneHistory.length){
          //   this.country.setValue(value);
          // }

          if (!this.country.value && this.timezoneHistory && this.timezoneHistory.length > 0 && this.timezoneHistory[0].country) {
            // Check if country value is empty, timezoneHistory exists and is not empty, and the first entry has a country
            this.country.setValue(value);
        }
        
        if (!this.timezoneHistory || this.timezoneHistory.length === 0) {
            // Check if timezoneHistory does not exist or is empty
            this.country.setValue(value);
        }
          this.orgTzFilteredOptions = momentTZ.tz.zonesForCountry(value.code).map(name => name + " (GMT" + momentTZ().tz(name).format("Z") + ')');
          // this.orgTimezone = null;
        },
          err => {
            console.log(err);
            this.country.setValue('');
            this.orgTimezone = null;
          })
      });

  }
  refreshCall() {
    this.loadTimezoneHistory()
  }
}
