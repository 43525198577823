<div class="container-fluid">
    <div class="contentsec pt-0">
        <!-- <fcsro-data-loader *ngIf="timezoneHistoryLoading"></fcsro-data-loader> -->
        <fcsro-place-holder *ngIf="timezoneHistoryLoading"></fcsro-place-holder>

        <div class="tableplatformlist" *ngIf="!isFirstTimeLoad">
            <h3 class="text-left d-inline-block py-0 mt-ms-2 mt-sm-0">{{"CUSTOM_TIMEZONE.TITLE" | translate }}</h3>
        </div>

        <div class="custom-data-wrapper customTimezone">
            <div class="row">
                <!-- <div class="col-12">
                        <div class="tableplatformlist">
                                <h5 class="text-primary">{{"TASKS.LOCAL_TIMEZONE" | translate}}</h5>
                                <p class="small">{{"CUSTOM_TIMEZONE.DESCRIPTION" | translate }}</p>
                                <alert type="success text-white bg-success" *ngIf="isLTZsuccess" (onClosed)="isLTZsuccess=false"
                                [dismissOnTimeout]=timeout>
                                 <div class="iq-alert-text">{{"TASKS.LOCAL_TIMEZONE" | translate}} has been successfully updated.</div>
                                </alert>
                        
                                <mat-form-field class="fnt_sz12 w-50" appearance="outline">
                                    <input class="fsi" matInput type="text" placeholder="{{'TASKS.LOCAL_TIMEZONE' | translate}} :" [matAutocomplete]="auto" [(ngModel)]="timeZoneSelect"
                                    (ngModelChange)="search()"  #inputAutoComplete>
                                    <button *ngIf="timeZoneSelect" matSuffix mat-icon-button aria-label="Clear"
                                        (click)="close($event);" class="clear_btn">
                                    <i class="ri-close-line d-inline-block" aria-hidden="true"></i></button>
                                </mat-form-field>
                                <mat-autocomplete (optionSelected)="timezoneChange()" #auto="matAutocomplete">
                                    <mat-option *ngFor="let name of filteredOptions;" [value]="name">{{name}}</mat-option>
                                </mat-autocomplete>
                        </div>
                    </div> -->

                <div class="col-12">
                    <div class="tableplatformlist">
                        <h5 class="text-primary" *ngIf="!isFirstTimeLoad"> {{"TASKS.ORG_TIMEZONE" | translate}}
                            <!-- <sup><i class="fa fa-question-circle-o mr-1"
                                        matTooltip="{{'SETTINGS.TIMEZONE_TOOLTIP' | translate}}"></i></sup> -->
                        </h5>
                        <p class="small" *ngIf="!isFirstTimeLoad">{{"TASKS.ORG_TIMEZONE_DESC" | translate}}</p>
                        <alert type="success text-white bg-success" *ngIf="isOrgTzSuccess"
                            (onClosed)="isOrgTzSuccess=false" [dismissOnTimeout]=timeout>
                            <div class="iq-alert-text">{{"TASKS.ORG_TIMEZONE" | translate}} has been successfully
                                updated.</div>
                        </alert>
                        <alert type="danger text-white bg-danger" *ngIf="isOrgTzFailed" (onClosed)="isOrgTzFailed=false"
                            [dismissOnTimeout]=timeout>
                            <div class="iq-alert-text">Error, Unable to update Organisation Timezone.</div>
                        </alert>
                        <div class="row">
                            <div class="col-md-5">
                                <mat-form-field class="country-full-width w-100" appearance="outline">
                                    <mat-label>{{"COMMON.SELECT_COUNTRY" | translate}}</mat-label>
                                    <input type="text" placeholder="country" aria-label="Country" matInput
                                        [formControl]="country" [matAutocomplete]="auto"
                                        (ngModelChange)="orgTzSearch()">
                                    <button *ngIf="country.value" matSuffix mat-icon-button aria-label="Clear"
                                        (click)="countrySelectClose($event);" class="clear_btn">
                                        <i class="ri-close-line d-inline-block" aria-hidden="true"></i></button>
                                </mat-form-field>
                                <mat-autocomplete #auto="matAutocomplete" [displayWith]="countryAutoCompleteDisplay"
                                    (optionSelected)="onCountrySelectChange($event.option.value)">
                                    <mat-option *ngFor="let option of countryFilteredOptions | async" [value]="option">
                                        {{option.name}}
                                    </mat-option>
                                </mat-autocomplete>
                            </div>
                            <div [ngClass]="isFirstTimeLoad ? 'col-md-7' : 'col-md-6'">

                                <mat-form-field class="fnt_sz12 w-100" appearance="outline">
                                    <mat-label>{{"COMMON.SELECT_TIMEZONE" | translate}}</mat-label>
                                    <input class="fsi" matInput type="text"
                                        placeholder="{{'TASKS.ORG_TIMEZONE' | translate}} :" [matAutocomplete]="auto1"
                                        [(ngModel)]="orgTimezone" (ngModelChange)="orgTzSearch()" [disabled]="timezoneDisable"
                                        >
                                    <button *ngIf="orgTimezone" matSuffix mat-icon-button aria-label="Clear"
                                        (click)="orgTzClose($event);" class="clear_btn">
                                        <i class="ri-close-line d-inline-block" aria-hidden="true"></i></button>
                                </mat-form-field>
                                <mat-autocomplete (optionSelected)="orgTzChange()" #auto1="matAutocomplete">
                                    <mat-option *ngFor="let name of orgTzFilteredOptions"
                                        [value]="name">{{name}}</mat-option>
                                </mat-autocomplete>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div class="row" *ngIf="timezoneHistory.length && !isFirstTimeLoad">
                <div class="col-12">
                    <div class="tableplatformlist">
                        <h5 class="text-primary">{{"TIMEZONE.TIMEZONE_HISTORY" | translate}}</h5>
                        <div class="table-responsive">
                            <table class="table pl-3 pr-3 table-hover table-striped">
                                <thead>
                                    <tr>
                                        <th>{{"TIMEZONE.ID" | translate}}</th>
                                        <th>{{"TIMEZONE.OLD_TIMEZONE" | translate}}</th>
                                        <th>{{"TIMEZONE.NEW_TIMEZONE" | translate}}</th>
                                        <th>{{"TIMEZONE.UPDATED_AT" | translate}}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let item of timezoneHistory">
                                        <td>{{item.id}}</td>
                                        <td>{{item.oldTimezone}}</td>
                                        <td>{{item.newTimezone}}</td>
                                        <td>{{item.createdAt | customTimezone | date:'dd-MM-yyyy h:mm a'}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>