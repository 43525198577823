import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class LanguageConfigService {
  private languageStorageKey = 'lang';
  private defaultLanguage = 'english';
  private languages: string[] = ['english', 'french', 'tamil', 'russian', 'chinese', 'hindi', 'spanish', 'arabic', 'catalan', 'german', 'portuguese', 'indonesian', 'turkey'];

  getCurrLanguage$: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(private translate: TranslateService) {
    this.initializeTranslation();
  }

  private initializeTranslation() {
    this.translate.setDefaultLang(this.defaultLanguage);

    const storedLanguage = localStorage.getItem(this.languageStorageKey);
    const languageToUse = storedLanguage ? storedLanguage : this.defaultLanguage;

    this.translate.use(languageToUse);
    this.getCurrLanguage$.next(languageToUse);
  }

  getLanguageList(): string[] {
    return this.languages;
  }

  getDefaultLanguage(): string {
    return this.defaultLanguage;
  }

  getCurrentLanguage(): string {
    const storedLanguage = localStorage.getItem(this.languageStorageKey);
    const currentLang = storedLanguage || this.defaultLanguage;
    return currentLang;
  }
  
  setCurrentLanguage(language: string): void {
    localStorage.setItem(this.languageStorageKey, language);
    this.translate.use(language);
    this.getCurrLanguage$.next(language);
  }
}
