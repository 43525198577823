import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilityService } from '../services/utility.service';
import { HTTPMethods } from './header-interceptor';

@Injectable()
export class SanitizerInterceptor implements HttpInterceptor {
    constructor(
        private util: UtilityService
    ) { }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (req.method.toUpperCase() === HTTPMethods.POST || req.method.toUpperCase() === HTTPMethods.PUT) {

            // Trim string values in request body
            const trimmedBody = this.util.trimStringsInObject(req.clone().body);
            // console.log('Trimmed body', trimmedBody);
            const sanitizedRequest = req.clone({
                body: { ...trimmedBody }
            });
            return next.handle(sanitizedRequest);
        }
        return next.handle(req);
    }
}
