<div class="container-fluid">
    <div class="row">
        <div class="col-sm-12">            
            <div class="tableplatformlist text-center py-5">
                <div class="loader">
                    <div class="app-spinner">
                      <div></div>
                      <div></div>
                      <div></div>
                      <div></div>
                    </div>
                  </div>
                <!-- <div class="data-loader-wrapper">
                    <div class="data-loader-ring">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div> -->
                <!-- <img src="assets/images/work-hours-screen-time-logging.png" class="mw-100"> -->
                <!-- <p class="mt-3">
                     {{ "LoadingText.Pleasebepatientwhileweloadyourdata" | translate }} 
                </p> -->
            </div>
        </div>
    </div>
</div>