import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { ITeam } from '../models/teams';
import { Router } from '@angular/router';
import { IPagination } from '../models/gallery';
import { UtilityService } from './utility.service';
import { ISort } from '../models/common';
import { TimeZoneService } from './time-zone.service';
import { OrganisationUser } from '../models/auth-user';
import { IAuthResponse } from './user-auth.service';

export interface ILoginCredentials {
  email: string;
  password: string;
  organisationCode: string;
}

export interface ITeamsResponseData {
  count: number;
  teams: { employeeId: number, team: ITeam }[];
}

export interface ITeamsResponse {
  statusCode: number;
  data: ITeamsResponseData;
}

export class LoggedInUser {
  id: number | any;
  email: string;
  fullName: string;
  designationId: number;
  designationName: string;
  organisationCode: string;
  roleId: number;
  customTaskName: string;
  customClientName: string;
  customProjectName: string;
  orgTimezone: string;
}

export class LoginResponseData {
  loggedInUser: LoggedInUser;
  token: string;
  refreshToken:string;
}

export class LoginResponseDataForAdmin {
  accessToken: string;
  refreshToken: string;
  userDetails: OrganisationUser;
}

export class LoginResponse {
  statusCode: number;
  data: LoginResponseData;
}

export class LoginResponseForAdmin {
  statusCode: number;
  message:string;
  data: LoginResponseDataForAdmin;
}

@Injectable({
  providedIn: 'root'
})
export class ManagerialOperationsService {
  private url = `${environment.apiEndpoint}/api/v1/managerial-operations`;

  constructor(
    private httpService: HttpClient,
    private router: Router,
    private util: UtilityService,
    private timezoneService: TimeZoneService
  ) { }

  private storeLoggedInUser(data: LoginResponseData, rememberSession: boolean) {
    if (rememberSession) {
      localStorage.setItem('FocusRoAuthManagerUserDetailsProvider', JSON.stringify(data.loggedInUser));
      localStorage.setItem('FocusRoAuthManagerUserToken', data.token);
    } else {
      sessionStorage.setItem('FocusRoAuthManagerUserDetailsProvider', JSON.stringify(data.loggedInUser));
      sessionStorage.setItem('FocusRoAuthManagerUserToken', data.token);
    }
    this.timezoneService.setTimeZone(data.loggedInUser.orgTimezone);
  }

  public getLoggedInUser(): string {
    const userDetails = sessionStorage.getItem('FocusRoAuthManagerUserDetailsProvider');
    if (userDetails) {
      return userDetails;
    }
    return localStorage.getItem('FocusRoAuthManagerUserDetailsProvider');
  }

  private authenticate(credentials: ILoginCredentials) {
    const reqUrl = `${this.url}/auth`;
    return this.httpService.post<LoginResponse>(reqUrl, credentials);
  }

  private fetchTeams(idToken: string, pagination?: IPagination, sort?: ISort, search?: string) {
    const reqUrl = `${this.url}/teams`;
    const query = this.util.generateListQueryParameters(pagination, sort, search);
    return this.httpService.get<ITeamsResponse>(reqUrl + query, {
      headers: {
        Authorization: `Bearer ${idToken}`
      }
    });
  }

  public isLoggedIn() {
    if (this.getLoggedInUser()) {
      return true;
    }
    return false;
  }

  public getCurrenUser(): LoggedInUser | null {
    const userString = this.getLoggedInUser();
    if (userString == null) {
      return null;
    }
    try {
      const temp = JSON.parse(userString);
      const loggedInUser = new LoggedInUser();
      loggedInUser.email = temp.email;
      loggedInUser.designationId = temp.designationId;
      loggedInUser.designationName = temp.designationName;
      loggedInUser.fullName = temp.fullName;
      loggedInUser.id = temp.id;
      loggedInUser.roleId = temp.roleId;
      loggedInUser.organisationCode = temp.organisationCode;
      loggedInUser.customTaskName = temp.customTaskName;
      return loggedInUser;
    } catch (e) {
      console.error(e);
      throw e;
    }
  }

  public getAuthToken(): string | null {
    const token = sessionStorage.getItem('FocusRoAuthManagerUserToken');
    if (token) {
      return token;
    }
    return localStorage.getItem('FocusRoAuthManagerUserToken');
  }

  public login(credentials: ILoginCredentials, rememberSession: boolean) {
    return this.authenticate(credentials).pipe(
      map((response) => {
        // console.log("response",response);
        this.storeLoggedInUserForManager(response.data, rememberSession);
        const time=this.timezoneService.setTimeZone(response.data.loggedInUser.orgTimezone);
        const intervel=this.getRefreshTokenIntervelForManager();
        // console.log("interval 5",intervel);
        setTimeout(()=>{
          this.refreshTokenForManager(credentials.organisationCode).subscribe();
        },intervel);
      })
    );
  }

  public logOut(employeeId: number, noRedirect?: boolean): void;
  public logOut(employeeId: number, noRedirect = false): void {
    const reqUrl = `${this.url}/logout`;
    const orgCode = JSON.parse(this.getLoggedInUser()).organisationCode;
    const reqBody = { 
                      managerId : employeeId,
                      organisationCode: orgCode                  
                    };
    this.httpService.post<IAuthResponse>(reqUrl,reqBody)
      .subscribe((response) => {
        localStorage.removeItem('FocusRoAuthManagerUserDetailsProvider');
        localStorage.removeItem('FocusRoAuthManagerUserToken');
        sessionStorage.removeItem('FocusRoAuthManagerUserDetailsProvider');
        sessionStorage.removeItem('FocusRoAuthManagerUserToken');
        if (!noRedirect) {
          this.router.navigateByUrl('/auth/login');
        }
      },(error) => {
        console.log(error.error)
      });
  }

  public getTeams(pagination?: IPagination, sort?: ISort, search?: string) {
    const token = this.getAuthToken();
    if (!token) {
      return throwError('Not Authenticated');
    }
    return this.fetchTeams(token, pagination, sort, search);
  }

  public storeLoggedInUserforAdmin(data: LoginResponseDataForAdmin, rememberSession: boolean) {
    if (rememberSession) {
      localStorage.setItem('FocusRoAuthUserDetailsProvider', JSON.stringify(data.userDetails));
      localStorage.setItem('FocusRoAuthUserToken', data.accessToken);
    } else {
      sessionStorage.setItem('FocusRoAuthUserDetailsProvider', JSON.stringify(data.userDetails));
      sessionStorage.setItem('FocusRoAuthUserToken', data.accessToken);
    }
  }

  private authenticateforAdmin(credentials: ILoginCredentials) {
    const reqUrl = `${environment.apiEndpoint}/api/v1/organisation-management/organisations/employee-admin-login`;
    return this.httpService.post<LoginResponseForAdmin>(reqUrl, credentials);
  }

  public getAuthTokenForAdmin(): string | null {
    const token = sessionStorage.getItem('FocusRoAuthUserToken');
    if (token) {
      return token;
    }
    return localStorage.getItem('FocusRoAuthUserToken');
  }

  public getRefreshTokenIntervel()
  {
    const currentTime = Date.now();
    const token = this.getAuthTokenForAdmin();  
    const expireTime = this.getTokenExpireTime(token)*1000;
    const time = expireTime - currentTime -(60*1000);//refresh token one minut before expire;
    return time;
  }

  public getTokenExpireTime(token: string)
  {
    const expire_time = (JSON.parse(atob(token.split('.')[1]))).exp;
    return expire_time;
  }

  public loginforadmin(credentials: ILoginCredentials, rememberSession: boolean) {
    return this.authenticateforAdmin(credentials).pipe(
      map((response) => {
        this.storeLoggedInUserforAdmin(response.data, rememberSession);
        this.timezoneService.setTimeZone(response.data.userDetails.orgTimezone);
        const intervel=this.getRefreshTokenIntervel();
        setTimeout(()=>{
          this.refreshToken().subscribe();
        },intervel);
      })
    );
  }

  public getRefreshToken(): string | null {
    const token = sessionStorage.getItem('FocusRoAuthUserRefreshToken');
    if (token) {
      return token;
    }
    return localStorage.getItem('FocusRoAuthUserRefreshToken');
  }

  public refreshToken()
  {
    return  new Observable((observer) => {
    const reqUrl = `${this.url}/refreshToken-Manager`;
    this.httpService.post<IAuthResponse>(reqUrl, {})
        .subscribe((response) => {
            const accessToken= response.data.accessToken;
            if(localStorage.getItem("FocusRoAuthUserToken"))
            {
              localStorage.setItem('FocusRoAuthUserToken', accessToken);
            }
            else if(sessionStorage.getItem("FocusRoAuthUserToken"))
            {
              sessionStorage.setItem('FocusRoAuthUserToken',accessToken);
            }
            const intervel=this.getRefreshTokenIntervel();
            setTimeout(()=>{
              this.refreshToken().subscribe();
            },intervel);

            observer.next(accessToken);
        }, (error) => {
            console.log(error.error);
            observer.next(error.error.message);
            const user= this.getCurrenUser();
            this.logOut(user.id);
        });
      }
    );
  }

  public refreshTokenForManager(orgCode:string)
  {
    return  new Observable((observer) => {
    const reqUrl = `${this.url}/refreshToken-Manager`;
    this.httpService.post<IAuthResponse>(reqUrl, { organisationCode: orgCode })
        .subscribe((response) => {
            const accessToken= response.data.accessToken;
            // const refreshToken= response.data.refreshToken;
            if(localStorage.getItem("FocusRoAuthManagerUserToken"))
            {
              localStorage.setItem('FocusRoAuthManagerUserToken', accessToken);
            }
            else if(sessionStorage.getItem("FocusRoAuthManagerUserToken"))
            {
              sessionStorage.setItem('FocusRoAuthManagerUserToken',accessToken);
            }
            const intervel=this.getRefreshTokenIntervelForManager();
            setTimeout(()=>{
              this.refreshTokenForManager(orgCode).subscribe();
            },intervel);

            observer.next(accessToken);
        }, (error) => {
            console.log(error.error);
            observer.next(error.error.message);
            const user= this.getCurrenUser();
            this.logOut(user.id);
        });
      }
    );
  }

  public getRefreshTokenIntervelForManager()
  {
    const currentTime = Date.now();
    // console.log("curent time 1: " + currentTime)
    const token = this.getAuthTokenForManager();  
    // console.log("token",token);
    const expireTime = this.getTokenExpireTimeForManager(token)*1000;
    // console.log("expire time 2 : " + expireTime)
    const time = expireTime - currentTime -(60*1000);//refresh token one minut before expire;
    // console.log("timeCheck 3",time);
    
    return time;
  }


  public getAuthTokenForManager(): string | null {
    const token = sessionStorage.getItem('FocusRoAuthManagerUserToken');
    if (token) {
      return token;
    }
    return localStorage.getItem('FocusRoAuthManagerUserToken');
  }


  private storeLoggedInUserForManager(data: LoginResponseData, rememberSession: boolean) {
    if (rememberSession) {
      localStorage.setItem('FocusRoAuthManagerUserDetailsProvider', JSON.stringify(data.loggedInUser));
      localStorage.setItem('FocusRoAuthManagerUserToken', data.token);
    } else {
      sessionStorage.setItem('FocusRoAuthManagerUserDetailsProvider', JSON.stringify(data.loggedInUser));
      sessionStorage.setItem('FocusRoAuthManagerUserToken', data.token);
    }
    this.timezoneService.setTimeZone(data.loggedInUser.orgTimezone);
  }


  public getTokenExpireTimeForManager(token: string)
  {
    // console.log('tokenManager:', token)
    // console.log('token first split Manager:', token.split('.'))
    // console.log('token Manager1:', token.split('.')[1])
    // console.log('token split atob Manager:', (atob(token.split('.')[1])))
    // console.log('JSON Parsed Token expire Time Manager :', (JSON.parse(atob(token.split('.')[1]))).exp)
    const expire_time = (JSON.parse(atob(token.split('.')[1]))).exp;
    return expire_time;
  }



}
