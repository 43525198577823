import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleGuard } from 'src/app/guard/role.guard';
import { Roles } from 'src/app/services/super-admin.service';
import { CustomTimezoneComponent } from './custom-timezone/custom-timezone.component';


const routes: Routes = [
  {
    path: 'timezone-customization',
    canActivate: [RoleGuard],
    component: CustomTimezoneComponent,
    data: {
      role: [Roles.OrgUser]
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TimeZoneRoutingModule { }
