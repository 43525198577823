import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MenuItem } from '../../lib/sofbox/models/menu-item';
import AppMenuConfig from '../config/menu.config.json';
import { AuthenticationService } from './authentication.service';
import { map } from 'rxjs/operators';
import { CurrentUser, OrganisationUser } from '../models/auth-user';
import { LoggedInUser } from './managerial-operations.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  constructor(
    private authService: AuthenticationService
  ) { }

  getMenuList(): Observable<MenuItem[]> {
    return this.authService.getCurretUser().pipe(
      map((userInfo) => {
        const meuns = AppMenuConfig.data as Array<MenuItem>;
        let roleId = 0;
        if (userInfo instanceof CurrentUser) {
          roleId = userInfo.UserDetails.roleId;
        } else if (userInfo instanceof LoggedInUser) {
          roleId = userInfo.roleId;
        }else if (userInfo instanceof OrganisationUser) {
          roleId = userInfo.roleId;
        }
        return meuns.filter((menuItem: MenuItem) => {
          if (menuItem.roles.includes(roleId)) {
            return true;
          }
          return false;
        });
      })
    );
  }
}
