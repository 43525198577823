import { Injectable } from '@angular/core';
import { CanActivate, CanActivateChild, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AuthenticationService } from '../services/authentication.service';
import { catchError } from 'rxjs/operators';
import { ManagerialOperationsService, LoggedInUser } from '../services/managerial-operations.service';
import { CurrentUser, OrganisationUser } from '../models/auth-user';
import { MenuService } from '../services/menu.service';
import { UserAuthService } from '../services/user-auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthenticationService,
    private managerOperations: ManagerialOperationsService,
    private menuService: MenuService,
    private router: Router,
    private uauthService: UserAuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isActivatable(next);
  }
  canActivateChild(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.isActivatable(next);
  }

  async isActivatable(next: ActivatedRouteSnapshot) {
    const userInfo = await this.authService.getCurretUser().pipe(
      catchError((err) => {
        console.error('RoleGulard#isActivatable', err);
        return of(null);
      })
    ).toPromise();
    if (userInfo) {
      const menus = await this.menuService.getMenuList().toPromise();
      if (userInfo instanceof CurrentUser) {
        if (!userInfo.UserDetails.roleId) {
          this.uauthService.logOut(userInfo.UserDetails.id);
          return false;
        }
        if (next.data.role.includes(userInfo.UserDetails.roleId)) {
          return true;
        }
        this.router.navigateByUrl(menus[0].link);
        return false;
      } else if (userInfo instanceof LoggedInUser) {
        if (!userInfo.roleId) {
          this.managerOperations.logOut(userInfo.id);
          return false;
        }
        if (next.data.role.includes(userInfo.roleId)) {
          return true;
        }
        this.router.navigateByUrl(menus[0].link);
      }else if (userInfo instanceof OrganisationUser) {
        if (!userInfo.roleId) {
          this.uauthService.logOut(userInfo.id);
          return false;
        }
        if (next.data.role.includes(userInfo.roleId)) {
          return true;
        }
        this.router.navigateByUrl(menus[0].link);
        return false;
      }
    }
    return false;
  }
}
