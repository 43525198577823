import { Injectable } from '@angular/core';

/* imports to use cognito authentication via amplify sdk */
// import { Auth, Hub } from 'aws-amplify';
// import { CognitoUser } from 'amazon-cognito-identity-js';

export interface IAuthResponse {
  /**
   * response message that states the result of the request
   */
  message: string;

  /**
   * The response/ error object sent by amplify sdk.
   */
  response: any;
}

@Injectable({
  providedIn: 'root'
})
/**
 * This service helps the application to connect with the
 * aws cognito service and allows the users of the app
 * to authorize and authenticate the app provided services.
 */
export class CognitoAuthService {

  constructor() { }

  // public listenAuthEvents(callback: any) {
  //   Hub.listen('auth', callback);
  // }

  // public getCurrentAuthenticatedUser(): Observable<any> {
  //   return from(Auth.currentAuthenticatedUser());
  // }

  // /**
  //  * Get the current signed in user's information
  //  */
  // public getCurrentUserInfo(): Observable<any> {
  //   return new Observable((observer) => {
  //     this.hasSignedIn().subscribe((isLoggedIn) => {
  //       if (isLoggedIn) {
  //         Auth.currentUserInfo()
  //           .then((userData) => {
  //             observer.next(userData);
  //           })
  //           .catch((error) => {
  //             observer.error(error);
  //           });
  //       } else {
  //         observer.error({
  //           message: 'no user has logged in',
  //           code: 'NoUserLoggedInException'
  //         });
  //       }
  //     });
  //   });
  // }

  // public getCurrentSession() {
  //   return new Observable<any>((userSession) => {
  //     Auth.currentSession().then((response) => {
  //       userSession.next(response);
  //     })
  //       .catch((error) => {
  //         userSession.error(error);
  //       });
  //   });
  // }

  // public hasSignedIn(): Observable<boolean> {
  //   return from(Auth.currentAuthenticatedUser()).pipe(
  //     catchError(() => of(false)),
  //     map((userInfo) => {
  //       if (userInfo) {
  //         return true;
  //       }
  //       return false;
  //     })
  //   );
  // }

  // public signOut(): Observable<IAuthResponse> {
  //   return new Observable<IAuthResponse>((observer) => {
  //     Auth.signOut()
  //       .then((result) => {
  //         const response: IAuthResponse = {
  //           message: 'Signed out succesfully',
  //           response: result
  //         };
  //         observer.next(response);
  //       })
  //       .catch((error) => {
  //         const response: IAuthResponse = {
  //           message: 'Signed out failed',
  //           response: error
  //         };
  //         observer.error(response);
  //       });
  //   });
  // }

  // /**
  //  * Signs in an user in to the app using the aws cognito service.
  //  * @param userEmail Email address the user uses to sign in
  //  * @param password Password the user uses to sign in
  //  */
  // public signIn(userEmail: string, password: string): Observable<IAuthResponse> {
  //   return new Observable<IAuthResponse>((observer) => {
  //     Auth.signIn(userEmail.trim(), password.trim()).then((result) => {
  //       const response: IAuthResponse = {
  //         message: 'Signed in succesfully',
  //         response: result
  //       };
  //       observer.next(response);
  //     })
  //       .catch((err) => {
  //         const response: IAuthResponse = {
  //           message: 'Sign in failed',
  //           response: err
  //         };
  //         observer.error(response);
  //       });
  //   });

  // }

  // /**
  //  * Signs up an user to the apps services via aws cognito service
  //  * @param userEmail email address the user uses to sign up
  //  * @param password password the user uses to sign up
  //  * @param orgName name of the user's organisation
  //  * @param orgNickName nick name of the user's organisation
  //  * @param orgWebsite the url to the organisation's website
  //  */
  // public signUp(
  //   userEmail: string,
  //   password: string,
  //   orgName: string,
  //   orgNickName: string,
  //   orgWebsite: string
  // ) {
  //   return new Observable<IAuthResponse>((observer) => {
  //     Auth.signUp({
  //       username: userEmail.trim(),
  //       password: password.trim(),
  //       attributes: {
  //         email: userEmail.trim(),
  //         name: orgName.trim(),
  //         nickname: orgNickName.trim(),
  //         website: orgWebsite.trim()
  //       }
  //     })
  //       .then((result) => {
  //         observer.next({
  //           message: 'signed up successfully',
  //           response: result
  //         });
  //       })
  //       .catch((err) => {
  //         observer.error({
  //           message: 'sign up failed',
  //           response: err
  //         });
  //       });
  //   });
  // }

  // public verifySignUp(userEmail: string, confirmCode: string) {
  //   return new Observable<IAuthResponse>((observer) => {
  //     Auth.confirmSignUp(userEmail.trim(), confirmCode.trim())
  //       .then((result) => {
  //         observer.next({
  //           message: 'Verification Successful',
  //           response: result
  //         });
  //       })
  //       .catch((err) => {
  //         observer.error({
  //           message: 'Verification Failed',
  //           response: err
  //         });
  //       });
  //   });

  // }

  // public resendVerifcationCode(userEmail: string) {
  //   return new Observable<IAuthResponse>((observer) => {
  //     Auth.resendSignUp(userEmail.trim())
  //       .then((result) => {
  //         observer.next({
  //           message: 'Verification Code Resent',
  //           response: result
  //         });
  //       })
  //       .catch((error) => {
  //         observer.error({
  //           message: 'Unable to Resend Verification Code',
  //           response: error
  //         });
  //       });
  //   });
  // }

  // public initPasswordReset(userEmail: string) {
  //   return new Observable<IAuthResponse>((observer) => {
  //     Auth.forgotPassword(userEmail.trim().trim())
  //       .then((result) => {
  //         observer.next({
  //           message: 'password reset code sent',
  //           response: result
  //         });
  //       })
  //       .catch((error) => {
  //         observer.error({
  //           message: 'unable to send password reset code',
  //           response: error
  //         });
  //       });
  //   });
  // }

  // public changePassword(userEmail: string, resetCode: string, newPassword: string) {
  //   return new Observable<IAuthResponse>((observer) => {
  //     Auth.forgotPasswordSubmit(userEmail.trim(), resetCode.trim(), newPassword.trim())
  //       .then((result) => {
  //         observer.next({
  //           message: 'passwrod changed successfully',
  //           response: result
  //         });
  //       })
  //       .catch((error) => {
  //         observer.error({
  //           message: 'unable to change the password',
  //           response: error
  //         });
  //       });
  //   });
  // }
}
