import { Component, OnInit } from '@angular/core';
import { of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { ManagerialOperationsService } from 'src/app/services/managerial-operations.service';
import { UserAuthService } from 'src/app/services/user-auth.service';

@Component({
  selector: 'fcsro-account-disabled-page',
  templateUrl: './account-disabled-page.component.html',
  styleUrls: ['./account-disabled-page.component.css']
})
export class AccountDisabledPageComponent implements OnInit {

  constructor(
    private authService: AuthenticationService,
    private uauthService: UserAuthService,
    private managerService: ManagerialOperationsService
  ) { }

  async ngOnInit() {  
    const userInfo = await this.authService.getCurretUser().pipe(
      catchError((err) => {
        console.error('RoleGulard#isActivatable', err);
        return of(null);
      })
    ).toPromise();
    this.uauthService.logOut(userInfo.userDetails.id);
    const manager = this.managerService.getCurrenUser();
    if(manager){
      this.managerService.logOut(manager.id,true);
    }
  }
}
