import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CognitoRedirectService {

  constructor( private router: Router) { }

  public cognitoUserRedirect()
  {
     let cognitoToken=localStorage.getItem('FocusroOrgUserDetailsProvider')
     let cognitoSession=sessionStorage.getItem("FocusroOrgUserDetailsProvider")
     if(cognitoToken || cognitoSession)
     {
          localStorage.clear();
          sessionStorage.clear()
          this.router.navigateByUrl('/auth/login');
     }
        
  }
}
