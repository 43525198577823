import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Error404Component } from './views/error404/error404.component';
import { AuthGuardService as AuthGuard } from './services/auth-guard.service';
import { Error403Component } from './views/error403/error403.component';
import { Error500Component } from './views/error500/error500.component';
import { AccountDisabledPageComponent } from './views/account-disabled-page/account-disabled-page.component';
import { Error402Component } from './views/error402/error402.component';


const routes: Routes = [
  {
    path: '',
    // loadChildren: () => import('./layouts/home-layout/home-layout.module').then(m => m.HomeLayoutModule)
    redirectTo: '/app',
    pathMatch: 'full'
  },
  {
    path: 'app',
    loadChildren: './layouts/app-layout/layout1.module#Layout1Module',
    canActivateChild: [AuthGuard]
  },
  {
    path: 'auth',
    loadChildren: './layouts/auth-layout/auth-layout.module#AuthLayoutModule'
  },
  {
    path: '403',
    component: Error403Component
  },
  {
    path: '500',
    component: Error500Component
  }, 
  {
    path: 'account-disabled',
    component: AccountDisabledPageComponent
  },
  { path: '**', component: Error404Component }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { enableTracing: false, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
