import { Injectable, OnDestroy } from '@angular/core';
import { CognitoAuthService } from './cognito-auth.service';
import { OrganisationUserService } from './organisation-user.service';
import { Router } from '@angular/router';
import { Observable, of, from, timer, Subject } from 'rxjs';
import { map, switchMap, retry, share, takeUntil } from 'rxjs/operators';
import { CurrentUser, OrganisationUser } from '../models/auth-user';
import { IOrganisationResponse } from '../models/organisation-user';
import { LoggedInUser, ManagerialOperationsService } from './managerial-operations.service';
import { TranslateService } from '@ngx-translate/core';

import { UserAuthService } from './user-auth.service';
export interface ISignUpForm {
  // cognitoUserID: string;
  name: string;
  email: string;
  nickName: string;
  domain: string;
  password: string;
  website: string;
}

export interface ISignInForm {
  email: string;
  password: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService implements OnDestroy {

  private canStopPolling = new Subject();
  private poll$: Observable<IOrganisationResponse>;
  public constructor(
    private authSer: CognitoAuthService,
    private orgSer: OrganisationUserService,
    private managerSer: ManagerialOperationsService,
    private router: Router,
    private translate: TranslateService,
    private uauthSer: UserAuthService
  ) { }

  public ngOnDestroy(): void {

    // Using OnDestroy lifecycle hook
    // to stop polling when
    // app is destroyed.
    this.stopPollingOrgUserDetails();
  }

  public storeLoggedInUser(loggedinUser: OrganisationUser): void {
    if(sessionStorage.getItem('FocusRoAuthUserDetailsProvider'))
    {
      sessionStorage.setItem('FocusRoAuthUserDetailsProvider', JSON.stringify(loggedinUser));
    }
    else
    {
      localStorage.setItem('FocusRoAuthUserDetailsProvider', JSON.stringify(loggedinUser));
    }
  }

  // private getCurrentSession() {
  //   return this.authSer.getCurrentSession();
  // }

  private async identifyLoggedInUser() {
    let currentUser: CurrentUser | LoggedInUser | OrganisationUser;
    // const isCognitoUser = await (this.authSer.hasSignedIn().toPromise());
    // if (isCognitoUser) {
    //   const loggedinUser = this.fetchLoggedInUser();
    //   if (loggedinUser) {
    //     currentUser = new CurrentUser();
    //     currentUser.CognitoUser = loggedinUser.CognitoUser;
    //     currentUser.UserDetails = loggedinUser.UserDetails;
    //     return currentUser;
    //   }
    //   const cognitoUser = await this.authSer.getCurrentAuthenticatedUser()
    //     .pipe(catchError(() => {
    //       return of(null);
    //     })).toPromise();
    //   if (cognitoUser) {
    //     const orgUser = await this.orgSer.getOrganisationByEmail(
    //       cognitoUser.attributes.email,
    //       cognitoUser.signInUserSession.idToken.jwtToken
    //     ).toPromise();
    //     currentUser = new CurrentUser();
    //     currentUser.CognitoUser = cognitoUser;
    //     currentUser.UserDetails = orgUser.organisation;
    //     this.storeLoggedInUser(currentUser);
    //     return currentUser;
    //   } else {
    //     await this.signOut().toPromise();
    //     throw new Error('Cognito: Unauthenticated');
    //   }
    // }
    currentUser = this.managerSer.getCurrenUser();
    if (currentUser) {
      return currentUser;
    } else {
      currentUser = this.uauthSer.getCurrenUser();
      if (currentUser) {
        return currentUser;
      } 
      else
      {
        throw new Error('UNAUTHENTICATED');
      }
    }
  }

  private pollOrgUserDetails() {
    const pollingInterval = 300000; // 5 minutes (in milliseconds)

    /*
    Using RxJs timer to poll server at set interval to
    check if user setup has been completed.

    Using RxJs switchMap operator to switch the number observable
    returned by timer to a new observable returning current user details

    Using RxJs retry operator to retry fetching organisation user
    details when the api call fails.

    Using RxJs share operator to prevent creating separate polling by each subsriber

    Using RxJs takeUntil operator to continue polling untill 'canStopPolling' Subject
    emits a value
    */
    return timer(0, pollingInterval).pipe(
      switchMap(async () => {
        // console.log('polling', new Date().toString());
        const user = this.uauthSer.getLoggedInUser();
        const loginUser = JSON.parse(user);
        const orgUser = await this.orgSer.getOrganisationByEmail(
          loginUser.email,
          this.uauthSer.getAuthToken()
        ).toPromise();
        if (orgUser.organisation.setupCompleted) {

          // Stop the polling when user setup has been completed.
          this.stopPollingOrgUserDetails();
          // console.log('polling stopped');

          // Redirect user to app page after user setup has been completed.
          this.router.navigateByUrl('/app');

          // Update the new user details in the local storage
          this.storeLoggedInUser(orgUser.organisation);
        }
        return orgUser;
      }),
      retry(),
      share(),
      takeUntil(this.canStopPolling)
    );
  }

  private stopPollingOrgUserDetails() {
    this.canStopPolling.next();
    this.poll$ = null;
  }

  public getCurretUser(): Observable<CurrentUser | LoggedInUser | OrganisationUser> {
    return from(this.identifyLoggedInUser()).pipe(
      map((user) => {
        if (user instanceof CurrentUser &&
          !user.UserDetails.setupCompleted) {
          if (this.poll$ === null || this.poll$ === undefined) {

            // Create a common poll observable to be
            // used by all subscribers
            this.poll$ = this.pollOrgUserDetails();
          }

          // Begin polling
          this.poll$.subscribe();
        }
        return user;
      })
    );
  }

  public getCurretUserOrgCode(): Observable<string> {
    return this.getCurretUser().pipe(
      map((userInfo) => {
        if (userInfo instanceof CurrentUser) {
          const orgCode = userInfo.UserDetails.organisationCode;
          return orgCode;
        } else if (userInfo instanceof LoggedInUser) {
          const orgCode = userInfo.organisationCode;
          return orgCode;
        }else if (userInfo instanceof OrganisationUser) {
          const orgCode = userInfo.organisationCode;
          return orgCode;
        } else {
          throw Error('Forbidden: Unable to identify logged in user');
        }
      })
    );
  }

  public getCurretUserRole(): Observable<number> {
    return this.getCurretUser().pipe(map((userInfo) => {
      if (userInfo instanceof CurrentUser) {
        return userInfo.UserDetails.roleId;
      }
      if(userInfo instanceof OrganisationUser)
      {
        return userInfo.roleId;
      }
      if (userInfo instanceof LoggedInUser) {
        return userInfo.roleId;
      }
    }));
  }

  // public listenAuthEvents() {
  //   this.authSer.listenAuthEvents(({ payload: { event, data } }) => {
  //     console.log('Auth event changed to', event);
  //     if (event === 'signIn') {
  //       this.router.navigateByUrl('/app');
  //     }

  //     if (event === 'signOut') {
  //       this.removeLoggedInUser();
  //       if (this.redirectOnSignOut) {
  //         this.router.navigateByUrl('/auth/login');
  //       }
  //     }
  //   });
  // }

  // public getIdToken(): Observable<string> {
  //   return this.getCurrentSession().pipe(map((session) => {
  //     return session.idToken.jwtToken;
  //   }));
  // }

  public signUp(request: ISignUpForm) {
    return this.orgSer.registerOrganisation({
      email: request.email,
      name: request.name,
      organisationCode: request.nickName,
      password: request.password
    });
    /*return new Observable((observer) => {
      this.orgSer.checkAvailability(request.email, request.nickName).pipe(
        concatMap((response) => {
          if (response.data.isEmailRegisterd && response.data.isOrgCodeRegisterd) {
            throw new Error('OrganisationExistsError');
          } else if (response.data.isEmailRegisterd) {
            throw new Error('EmailExistsError');
          } else if (response.data.isOrgCodeRegisterd) {
            throw new Error('OrganisationCodeExistsError');
          } else {
            return this.authSer.signUp(request.email, request.password, request.name, request.nickName, request.website);
          }
        })
      )
        .subscribe((response) => {
          if (response.response.userSub) {
            const org: IRegisterOrgReq = {
              name: request.name,
              nickName: request.nickName,
              email: request.email,
              domain: request.domain,
              cognitoUserID: response.response.userSub
            };
            this.orgSer.registerOrganisation(org).subscribe(
              (res) => {
                console.log('Registration success.', res);
                observer.next(res);
              },
              (err) => {
                observer.error(err);
              }
            );
          } else {
            observer.error({
              message: 'Unable to get cognito user Id from AWS cognito'
            });
          }
        },
          (error) => {
            observer.error(error);
          });
    });*/
  }

  // public signIn(request: ISignInForm) {
  //   return new Observable<CurrentUser>((observer) => {
  //     this.authSer.signIn(request.email, request.password).subscribe((authResponse) => {
  //       const congitoUser = authResponse.response;
  //       this.orgSer.getOrganisationByEmail(
  //         congitoUser.attributes.email,
  //         congitoUser.signInUserSession.idToken.jwtToken
  //       ).subscribe((appUserResponse) => {
  //         const loggedinUser: CurrentUser = {
  //           CognitoUser: congitoUser,
  //           UserDetails: appUserResponse.organisation
  //         };
  //         this.storeLoggedInUser(loggedinUser);
  //         let loginTime = new Date();

  //         // Get the login time from cognito
  //         if (
  //           congitoUser.signInUserSession &&
  //           congitoUser.signInUserSession.idToken &&
  //           congitoUser.signInUserSession.idToken.payload &&
  //           congitoUser.signInUserSession.idToken.payload.auth_time
  //         ) {
  //           loginTime = new Date(congitoUser.signInUserSession.idToken.payload.auth_time * 1000);
  //         }
  //         // Update the login time for the user
  //         this.orgSer.updateLastLogin(
  //           appUserResponse.organisation.organisationCode,
  //           loginTime
  //         )
  //           .subscribe((res) => {
  //             console.log('Successfully updated login timestamp', res);
  //           }, (err) => {
  //             console.error('Failed to update login timestamp', err);
  //           });
  //         observer.next(loggedinUser);
  //       },
  //         (error) => {

  //           // force sign out incase of error
  //           this.signOut(true).subscribe();
  //           observer.error({
  //             message: 'Unable to get app user details',
  //             response: {
  //               code: 'USER_NOT_EXIST',
  //               name: 'USER_NOT_EXIST',
  //               message: 'User doesnot exist'
  //             }
  //           });
  //         });
  //     },
  //       (error) => {
  //         observer.error(error);
  //       });
  //   });
  // }

  // public signOut(noRedirect?: boolean): Observable<IAuthResponse>;
  /**
   * Signout of the application
   * @param noRedirect (optional) when true, does not redirect to login page.
   */
  // public signOut(noRedirect = false): Observable<IAuthResponse> {
  //   this.redirectOnSignOut = !noRedirect;

  //   // Stop polling when user signs out
  //   this.stopPollingOrgUserDetails();
  //   return this.uauthSer.signOut();
  // }

  public hasSignedIn(): Observable<boolean> {
    if(this.uauthSer.isLoggedIn || this.managerSer.isLoggedIn)
    {
      return of(true);
    }
    else
    {
      return of(false);
    }
  }
}
